<template>
  <div class="products">
    <Breadcrumb :items="breadcrumb" :key="category.categoryId" />

    <v-container fluid>
      <ProductListGrid
        v-bind:parentCategoryId="category.categoryId"
        :key="category.categoryId"
        :category="category"
        @productsCount="updateCount"
        position="category_brand"
        :footerText="footerText"
      >
        <template #category-title>
          <v-row no-gutters>
            <v-col
              v-if="brandImage"
              cols="12"
              :md="description ? 6 : 12"
              class="pr-3 d-flex justify-center align-center"
            >
              <v-img
                v-if="brandImage"
                :src="brandImage"
                contain
                class="elevation-0 rounded-0"
                @load="loaded = true"
              >
              </v-img>
            </v-col>
            <v-col v-if="description" cols="12" :md="brandImage ? 6 : 12">
              <h1
                v-if="!category.metaData.category_info.HIDE_TITLE"
                class="primary--text text--lighten-1 text-left font-weight-bold mt-3 mt-sm-0"
              >
                {{ title }}
              </h1>
              <div v-html="description"></div>
            </v-col>
          </v-row>
        </template>
        <template #category-block-position-1>
          <category-block
            :target="category"
            position="position1"
            class="category-block category-block-1"
            :container="false"
          />
        </template>
        <template #category-block-position-3>
          <category-block
            :target="category"
            position="position3"
            class="category-block category-block-3"
            :container="false"
          />
        </template>
        <template #category-block-position-4>
          <category-block
            :target="category"
            position="position4"
            class="category-block category-block-4"
            :container="false"
          />
        </template>
      </ProductListGrid>
    </v-container>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.products {
  .category-title {
    margin-top: -17px;
  }
}

:deep.footer-text {
  background-color: transparent !important;
  h2 {
    color: var(--v-primary-lighten1) !important;
    font-weight: 400;
    font-size: 31px;
    margin-top: 14px;
    margin-bottom: 7px;
  }
}
</style>
<script>
import ProductListGrid from "@/components/product/ProductListGrid";
import Breadcrumb from "@/components/navigation/Breadcrumb";

import categoryMixins from "~/mixins/category";

import get from "lodash/get";

export default {
  name: "CategoryBrand",
  mixins: [categoryMixins],
  components: {
    ProductListGrid,
    Breadcrumb
  },
  data() {
    return { count: -1 };
  },
  computed: {
    title() {
      return this.$ebsn.meta(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    brandImage() {
      return this.$ebsn.meta(
        this.category,
        "metaData.category_info.HEADER_IMAGE",
        null
      );
    },
    description() {
      return this.$ebsn.meta(
        this.category,
        "metaData.category_info.DESCRIPTION",
        null
      );
    },
    footerText() {
      return this.$ebsn.meta(
        this.category,
        "metaData.category_info.FOOTER_TEXT",
        null
      );
    }
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  metaInfo() {
    let text = this.category.name;
    if (this.breadcrumb.length > 0) {
      text = this.breadcrumb[this.breadcrumb.length - 2].text;
    }
    return {
      title: get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.secondAndThirdLevel.description", [
              this.category.name,
              text
            ])
          )
        }
      ]
    };
  }
};
</script>
